<template>
  <ModelEditor
    v-if="wheel"
    :title="title"
    :model="wheel"
    :errors="errors"
    :loading="$apollo.loading"
    :canSave="isDirty"
    @save="save"
    @cancel="restore"
  >
    <WheelStyled v-if="wheel">
      <AutoForm :model.sync="wheel" :modelName="'Wheel'" :filterFields="filterFields" />
      <ModelAssociator
        :title="'Assets'"
        :models="assets"
        :sourceModelName="'wheel'"
        :targetModelName="'asset'"
        :targetModelNamePlural="'assets'"
        :whereSearchInputType="'AssetWhereInput'"
        :whereSearchVariable="['name_contains', 'id_contains']"
        @connect="addAsset"
        @disconnect="deleteAsset"
      />
      <MembershipManager
        :title="'Members'"
        :memberships="wheel.memberships"
        :options="membershipMananagerOptions"
        :targetId="wheelId"
        :subsidiaries="wheel.subsidiaries"
      />
      <SubsidiariesManager :wheel="wheel" />
    </WheelStyled>
  </ModelEditor>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import ModelEditor from '@/components/misc/ModelEditor'
import { AutoForm, ModelAssociator, MembershipManager, SubsidiariesManager } from '@common/components'

import WHEEL_QUERY from '#/graphql/wheels/show.gql'
import UPDATE_WHEEL_MUTATION from '#/graphql/wheels/updateWheel.gql'

const WheelStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
`

export default {
  components: {
    WheelStyled,
    ModelEditor,
    ModelAssociator,
    MembershipManager,
    SubsidiariesManager,
    AutoForm,
  },
  data() {
    return {
      errors: [],
      wheel: null,
      wheelLocal: null,
      disabledFields: [],
      filterFields: ['memberships', 'assets'],
      membershipMananagerOptions: {
        enumName: 'WheelMembershipRoleEnum',
        mode: 'user',
      },
    }
  },
  computed: {
    title() {
      return `Wheel ${get(this.wheel, 'name', '')}`
    },
    wheelId() {
      return get(this.wheel, 'id', null)
    },
    isDirty() {
      return !isEqual(this.wheel, this.wheelLocal)
    },
    assets() {
      return get(this.wheel, 'assets', []).map(asset => ({
        ...asset,
        label: asset.name,
      }))
    },
  },
  methods: {
    async save() {
      const data = { ...this.wheel }
      delete data.id
      delete data.__typename
      this.filterFields.forEach(key => {
        delete data[key]
      })
      this.disabledFields.forEach(key => {
        delete data[key]
      })
      data.subsidiaries = []
      const res = await this.$apollo.mutate({
        mutation: UPDATE_WHEEL_MUTATION,
        variables: {
          where: {
            id: this.$route.params.id,
          },
          data,
        },
      })

      this.wheelLocal = get(res, 'data.updateWheel', null)
    },
    async restore() {
      this.wheel = this.wheelLocal
    },
    async addOrDeleteEntity(id, mode, entity) {
      const wheelID = this.$route.params.id
      if (!wheelID || !entity || !mode) {
        return
      }
      await this.$apollo.mutate({
        mutation: UPDATE_WHEEL_MUTATION,
        variables: {
          where: {
            id: wheelID,
          },
          data: {
            [entity]: {
              [mode]: {
                id,
              },
            },
          },
        },
      })
    },
    async addAsset({ id }) {
      return this.addOrDeleteEntity(id, 'connect', 'assets')
    },
    async deleteAsset({ id }) {
      return this.addOrDeleteEntity(id, 'disconnect', 'assets')
    },
  },
  apollo: {
    wheel: {
      query: WHEEL_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      skip() {
        return !this.$route.params.id
      },
      manual: true,
      result({ data }) {
        this.wheel = get(data, 'wheel')
        this.wheelLocal = get(data, 'wheel')
      },
    },
  },
}
</script>
