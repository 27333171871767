var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.wheel ? _c('ModelEditor', {
    attrs: {
      "title": _vm.title,
      "model": _vm.wheel,
      "errors": _vm.errors,
      "loading": _vm.$apollo.loading,
      "canSave": _vm.isDirty
    },
    on: {
      "save": _vm.save,
      "cancel": _vm.restore
    }
  }, [_vm.wheel ? _c('WheelStyled', [_c('AutoForm', {
    attrs: {
      "model": _vm.wheel,
      "modelName": 'Wheel',
      "filterFields": _vm.filterFields
    },
    on: {
      "update:model": function updateModel($event) {
        _vm.wheel = $event;
      }
    }
  }), _c('ModelAssociator', {
    attrs: {
      "title": 'Assets',
      "models": _vm.assets,
      "sourceModelName": 'wheel',
      "targetModelName": 'asset',
      "targetModelNamePlural": 'assets',
      "whereSearchInputType": 'AssetWhereInput',
      "whereSearchVariable": ['name_contains', 'id_contains']
    },
    on: {
      "connect": _vm.addAsset,
      "disconnect": _vm.deleteAsset
    }
  }), _c('MembershipManager', {
    attrs: {
      "title": 'Members',
      "memberships": _vm.wheel.memberships,
      "options": _vm.membershipMananagerOptions,
      "targetId": _vm.wheelId,
      "subsidiaries": _vm.wheel.subsidiaries
    }
  }), _c('SubsidiariesManager', {
    attrs: {
      "wheel": _vm.wheel
    }
  })], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }